import ShowValidField from './ShowValidField';
import ShowInvalidField from './ShowInvalidField';
export var FormValidator = function FormValidator(formId, Rules) {
  var res = true;
  var form = document.getElementById(formId);
  var inputs = form.querySelectorAll("input,select,textarea");

  for (var i = 0; i < inputs.length; i++) {
    if (window.getComputedStyle(inputs[i]) === 'hidden') {
      continue;
    }

    var value = void 0;

    if (inputs[i].getAttribute('type') == 'checkbox') {
      if (inputs[i].checked) {
        value = inputs[i].value;
      }
    } else {
      value = inputs[i].value;
    }

    var id = inputs[i].getAttribute('id');
    var validationResponse = void 0;

    if (Rules[id]) {
      if (typeof Rules[id] == 'object') {
        validationResponse = Rules[id].rule(value, Rules[id].param);
      } else {
        validationResponse = Rules[id](value);
      }

      ShowValidation(id, validationResponse);

      if (!validationResponse.status) {
        res = false;
      }

      ;
    }
  }

  return res;
};
export var ShowValidation = function ShowValidation(id, response) {
  if (response.status == false) {
    ShowInvalidField(id, response.msg);
  } else {
    ShowValidField(id, response.msg);
  }
};
export var ResetValidation = function ResetValidation(formId) {
  var form = document.getElementById(formId);
  var inputs = form.querySelectorAll("input,select");

  for (var i = 0; i < inputs.length; i++) {
    inputs[i].classList.remove('is-valid');
  }
};
export var ScrollToError = function ScrollToError(formId) {
  var form = document.getElementById(formId);
  var invalidField = form.getElementsByClassName('is-invalid');

  if (invalidField.length > 0) {
    invalidField[0].parentNode.scrollIntoView({
      behavior: "smooth"
    });
  }
};
export var FormChecker = function FormChecker(formId, Rules) {
  var res = true;
  var validationResult = {};
  var form = document.getElementById(formId);
  var inputs = form.querySelectorAll("input,select,textarea");

  for (var i = 0; i < inputs.length; i++) {
    if (window.getComputedStyle(inputs[i]) === 'hidden') {
      continue;
    }

    var value = void 0;
    var id = inputs[i].getAttribute('id');
    var inputType = inputs[i].getAttribute('type');

    if (inputType == 'checkbox') {
      if (inputs[i].checked) {
        value = inputs[i].value;
      } else {
        value = null;
      }
    } else if (inputType == 'radio') {
      if (inputs[i].checked) {
        value = inputs[i].value;
      }

      id = inputs[i].getAttribute('name');
    } else {
      value = inputs[i].value;
    }

    var validationResponse = void 0;

    if (Rules[id]) {
      if (typeof Rules[id] == 'object') {
        validationResponse = Rules[id].rule(value, Rules[id].param);
      } else {
        validationResponse = Rules[id](value);
      }

      if (inputType == 'radio' && validationResult.hasOwnProperty(id) && validationResult[id].field == 'is-invalid' && !value) {
        continue;
      } else {
        validationResult[id] = {
          help: validationResponse.status ? 'valid-feeback' : 'invalid-feedback',
          field: validationResponse.status ? 'is-valid' : 'is-invalid',
          error: validationResponse.msg
        };
      }

      if (!validationResponse.status) {
        res = false;
      }

      ;
    }
  }

  return {
    status: res,
    data: validationResult
  };
};