var __jsx = React.createElement;
import React from 'react';
import styles from '../styles/elements/ErrorMessage.module.scss';

var ErrorMessage = function ErrorMessage(props) {
  var title = props.title,
      message = props.message;
  return __jsx("div", {
    className: styles.root
  }, __jsx("p", {
    className: styles.title
  }, title), __jsx("p", {
    className: styles.message
  }, message));
};

export default ErrorMessage;