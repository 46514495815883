var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchProfile } from '../components/login/userSlice';
import Image from 'next/image';
import Link from 'next/link';
import ConsoHead from '../components/header/ConsoHead';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LoginBlock from '../components/login/LoginBlock';
import dynamic from 'next/dynamic';
var ConsoFooter = dynamic(function () {
  return import('../components/footer/ConsoSmallFooter');
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('../components/footer/ConsoSmallFooter')];
    },
    modules: ["login.js -> " + '../components/footer/ConsoSmallFooter']
  }
});

var Login = function Login(props) {
  var user = useSelector(function (state) {
    return state.user;
  });
  var router = useRouter();
  var query = router.query;
  var dispatch = useDispatch();
  useEffect(function () {
    if (user.status == 'idle') {
      dispatch(fetchProfile());
    }
  }, [user.isLoggedIn]);
  useEffect(function () {
    if (user.isLoggedIn && !query.hasOwnProperty('redirect')) {
      router.push('/');
    }
  }, [user.isLoggedIn]);
  var seo = {
    title: "Login",
    description: "Login - Conectează-te în contul tau Conso. ",
    canonical: process.env.NEXT_PUBLIC_SITE_URL + '/login'
  };
  return __jsx(React.Fragment, null, __jsx(ConsoHead, {
    seo: seo
  }), __jsx("div", {
    className: "mb-0 pb-0 bg-home",
    id: "main"
  }, __jsx(Container, null, __jsx(Row, {
    className: "justify-content-center flex-column bg-home m-0 py-3"
  }, __jsx("div", {
    className: "text-center py-3"
  }, __jsx(Link, {
    href: process.env.NEXT_PUBLIC_WEB
  }, __jsx("a", {
    title: "Conso"
  }, __jsx(Image, {
    src: "/images/icons/conso.png",
    width: 111,
    height: 33,
    classes: "",
    alt: "Conso"
  })))), __jsx(LoginBlock, {
    xs: 12,
    sm: 8,
    lg: 6,
    redirectUrl: query.hasOwnProperty('redirect') ? query.redirect : '/',
    openAccountLink: true
  })))), __jsx(ConsoFooter, null));
};

export default Login;