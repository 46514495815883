import { getNextSibling, getClosest } from '../dom/findElement';

var ShowValidField = function ShowValidField(id, message) {
  var field = document.getElementById(id);
  var info;

  if (field.getAttribute('type') == 'radio') {
    field.classList.remove("is-invalid");
    field.classList.add("is-valid");
    var label = getNextSibling(field, "label");
    label.style.color = "#3f4040";
    var wrapper = getClosest(field, "div.custom-radio");
    info = getNextSibling(wrapper, '.helper');
    info.style.display = "block";
  } else if (field.getAttribute('type') == 'checkbox') {
    field.classList.remove("is-invalid");
    field.classList.add("is-valid");

    var _label = getNextSibling(field, "label");

    _label.style.color = "#3f4040";

    var _wrapper = getClosest(field, "div.custom-checkbox");

    info = getNextSibling(_wrapper, '.helper');
    info.style.display = "block";
  } else {
    field.classList.remove("is-invalid");
    field.classList.add("is-valid");
    info = getNextSibling(field, '.helper');
  }

  if (info) {
    info.textContent = '';
    info.textContent = message;
    info.classList.remove('invalid-feedback');
    info.classList.add('valid-feedback');
  }
};

export default ShowValidField;